<template>
    <v-app>
        <LangSwitch v-if="$route.name !== '404'" />
        <v-container fluid class="fill-height">
            <v-row justify="center">
                <v-col cols="12" sm="12" md="10" lg="8">
                    <router-view />
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import LangSwitch from "./components/SwitchLanguage.vue";
import { mapState } from "vuex";

export default {
    components: { LangSwitch },
    mounted() {
        const quest = localStorage.getItem(`quests_v2`);
        if (typeof quest === "string") {
            localStorage.removeItem("quests_v2");
        }
        this.tokenWatcher = this.$watch(
            () => this.token,
            (newToken) => {
                if (newToken) {
                    this.$store.dispatch("fetchItems");
                    this.$store.dispatch("getuser").catch(() => {
                        this.$store.dispatch("logout");
                        this.$router.push("/sign-in").catch(() => {});
                    });
                }
            },
            { immediate: true }
        );
    },

    beforeDestroy() {
        this.tokenWatcher();
    },
    computed: {
        ...mapState(["user", "token"]),
    },
    methods: {},
};
</script>

<style scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}
</style>
