import api from "./api";
import items from "./assets/json/shortnames.json";

export const getItems = async () => {
    try {
        const response = await api.get("items.json");
        return response.data;
    } catch (error) {
        console.error(error);

        try {
            return items.map((item) => ({
                ...item,
            }));
        } catch (fileError) {
            console.error(fileError);
        }
    }
};
