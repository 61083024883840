export function customShuffle(array) {
    const result = [...array];
    let currentIndex = result.length,
        temporaryValue,
        randomIndex;

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = result[currentIndex];
        result[currentIndex] = result[randomIndex];
        result[randomIndex] = temporaryValue;
    }

    return result;
}

export function GenerateRandomQuestId() {
    return Math.floor(Math.random() * 100000);
}
