import Vue from "vue";
import VueRouter from "vue-router";

import guest from "../router/middleware/guest";
import auth from "../router/middleware/auth";
import store from "../store/index";

const NotFound = () => import("../views/404.vue");
const XDQuestLayout = () => import("../views/XDQuest/XDQuestLayout.vue");
const StartInfo = () => import("../views/XDQuest/StartInfo.vue");
const NewQuest = () => import("../views/XDQuest/NewQuest.vue");
const Login = () => import("../views/Login.vue");
const EnWiki = () => import("../views/XDQuest/EnWiki.vue");
const RuWiki = () => import("../views/XDQuest/RuWiki.vue");
const Quests = () => import("../views/XDQuest/Quests.vue");

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: XDQuestLayout,
        children: [
            {
                path: "",
                name: "Start",
                component: StartInfo,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "quests",
                name: "Quests",
                component: Quests,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "quests/new",
                name: "Quests add",
                component: NewQuest,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "quests/edit/:id",
                name: "Quest edit",
                component: NewQuest,
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: "ru/wiki",
                name: "RuWiki",
                meta: { title: "Wiki quest", middleware: [auth] },
                props: (route) => ({ hash: route.hash }),
                component: RuWiki,
            },
            {
                path: "en/wiki",
                name: "EnWiki",
                component: EnWiki,
                props: (route) => ({ hash: route.hash }),
                meta: {
                    middleware: [auth],
                },
            },
        ],
    },
    {
        path: "/sign-in",
        name: "Login",
        component: Login,
        meta: {
            middleware: [guest],
        },
    },
    {
        path: "*",
        name: "404",
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next();
    }
    const middleware = to.meta.middleware;
    const context = {
        to,
        from,
        next,
        store,
    };
    return middleware[0]({
        ...context,
    });
});
export default router;
