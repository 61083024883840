import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import config from "./config/index";
import axios from "axios";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import optionsToast from "./plugins/notification";
import VueLazyload from "vue-lazyload";

const loadimage = require("@/assets/hearts.svg");

const errorimage = require("@/assets/itemLoadError.png");

window.axios = axios;
window.config = config;

Vue.component("X-Confirm", () => import("./components/ConfirmModal.vue"));
Vue.component("X-QuestModal", () => import("./components/QuestFormModal.vue"));
const token = localStorage.getItem("token");
if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
Vue.config.productionTip = false;
Vue.prototype.$store = store;
Vue.use(Toast, optionsToast);
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: errorimage,
    loading: loadimage,
    attempt: 1,
});

Vue.mixin({
    methods: {
        setDocumentTitle(title) {
            document.title = `XDQuest Creater - ${title}`;
        },
    },
});

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
