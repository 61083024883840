/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from "axios";
import { getItems } from "@/itemsService";
import { pascalCase } from "eslint-plugin-vue/lib/utils/casing";
import { GenerateRandomQuestId } from "@/utils";
import { isLongTypes, transformOldQuestToNew } from "@/Help/HelpQuests";

const buffer = require("buffer/").Buffer;

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: localStorage.getItem("token"),
        isLoggedIn: false,
        user: {},
        status: "",
        quests: [],
        items: [],
        lang: localStorage.getItem("user_lang"),
        redirectAfterLogin: "",
    },
    getters: {
        isLoggedIn: (state) => !!state.token,
        IsRuLang: (state) => state.lang === "ru",
        authStatus: (state) => state.status,
        rustItems: (state) => state.items,
        user: (state) => state.user,
        quests: (state) => {
            return state.quests;
        },
        redirectAfterLogin: (state) => state.redirectAfterLogin,
    },

    mutations: {
        SET_REDIRECT_AFTER_LOGIN(state, path) {
            state.redirectAfterLogin = path;
        },
        SAVE_QUESTS(state, data) {
            data?.forEach((item) => {
                if (typeof item.Target === "string" && (isLongTypes(item.QuestType) || item.Target.includes(", "))) {
                    item.Target = item.Target.split(", ");
                }
                if (!Object.prototype.hasOwnProperty.call(item, "QuestID") || !item.QuestID || item.QuestID === 0) {
                    item.id = GenerateRandomQuestId();
                }
            });
            state.quests = data;
            localStorage.setItem("quests_v3", JSON.stringify(data));
        },
        MIX_QUESTS(state, newQuest) {
            state.quests = newQuest;
            localStorage.setItem("quests_v3", JSON.stringify(state.quests));
        },
        UPDATE_LANG(state, newLang) {
            state.lang = newLang;
            localStorage.setItem("user_lang", newLang);
        },
        ADD_QUESTS(state, data) {
            state.quests = state.quests || [];
            state.quests.push(data);
            localStorage.setItem("quests_v3", JSON.stringify(state.quests));
        },
        EDIT_QUEST(state, data) {
            const { id, quest } = data;
            state.quests[id] = quest;
            localStorage.setItem("quests_v3", JSON.stringify(state.quests));
        },
        REMOVE_QUEST(state, index) {
            state.quests.splice(index, 1);
            if (state.quests.length === 0) {
                localStorage.removeItem("quests_v3");
            } else {
                localStorage.setItem("quests_v3", JSON.stringify(state.quests));
            }
        },
        REMOVE_QUEST_LIST(state) {
            state.quests = [];
        },
        auth_request(state) {
            state.status = "loading";
        },
        auth_success(state, data) {
            state.status = "success";
            state.token = data.token;
            state.user = data.user;
            state.isLoggedIn = true;
        },
        auth_error(state) {
            state.status = "error";
        },
        set_info_user(state, data) {
            state.user = data;
        },
        logout(state) {
            state.status = "";
            state.token = "";
            localStorage.removeItem("token");
            delete axios.defaults.headers.common["Authorization"];
        },
        setItems(state, items) {
            state.items = items;
        },
    },
    actions: {
        async fetchItems({ commit }) {
            const items = await getItems();
            commit("setItems", items);
        },
        loadQuests({ commit }) {
            const quests = JSON.parse(localStorage.getItem("quests_v3"));
            commit("SAVE_QUESTS", quests);
        },
        mixQuests({ commit }) {
            commit("MIX_QUESTS");
        },
        removeQuest({ commit }, index) {
            commit("REMOVE_QUEST", index);
        },
        addNewQuest({ commit }, quest) {
            commit("ADD_QUESTS", quest);
        },
        editQuest({ commit }, data) {
            commit("EDIT_QUEST", data);
        },
        removeQuestList({ commit }) {
            commit("REMOVE_QUEST_LIST");
        },

        logout({ commit }) {
            return new Promise((resolve) => {
                commit("logout");
                localStorage.removeItem("token");
                delete axios.defaults.headers.common["Authorization"];
                resolve();
            });
        },
        getuser({ commit }) {
            return new Promise((resolve, reject) => {
                window
                    .axios({
                        url: window.config.API_LOCATION + "users/",
                        method: "POST",
                    })
                    .then(({ data }) => {
                        commit("set_info_user", data);
                        resolve();
                    })
                    .catch((err) => {
                        reject();
                    });
            });
        },
        downloadQuestJson({ commit }, quest) {
            return new Promise((resolve, reject) => {
                window
                    .axios({
                        url: window.config.API_LOCATION + "quest/",
                        method: "POST",
                        data: quest,
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },

        login({ commit, dispatch }, user) {
            return new Promise((resolve, reject) => {
                commit("auth_request");
                axios({
                    url: window.config.API_LOCATION + "users/login",
                    method: "POST",
                    data: user,
                    config: { headers: { "Content-Type": "multipart/form-data" } },
                })
                    .then((response) => {
                        const token = response.data.token;
                        const user = response.data.user;
                        localStorage.setItem("token", token);
                        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

                        const data = {
                            token,
                            user,
                        };
                        commit("auth_success", data);

                        resolve({
                            message: "AuthSucces",
                        });
                    })
                    .catch(function (error) {
                        commit("auth_error");
                        localStorage.removeItem("token");
                        reject({
                            message: error.response.data.message,
                        });
                    });
            });
        },

        async parseFile({ commit, dispatch }, data) {
            const reader = new FileReader();

            reader.readAsText(data);

            try {
                const result = await new Promise((resolve, reject) => {
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = () => reject(reader.error);
                });

                let questObjData = JSON.parse(result);
                if (questObjData.data) {
                    questObjData = JSON.parse(buffer.from(questObjData.data, "base64"));
                }
                let questData = Object.keys(questObjData).map((questObjKey) => {
                    let quest = questObjData[questObjKey];

                    if (Object.prototype.hasOwnProperty.call(quest, "id")) {
                        quest = transformOldQuestToNew(quest);
                    }
                    return quest;
                });
                questData = await dispatch("pascalCaseInCamel", questData);
                commit("SAVE_QUESTS", questData);
                await router.push("/quests");
            } catch (error) {
                console.error("Error parsing file:", error);
            }
        },

        async pascalCaseInCamel({ dispatch }, obj) {
            if (typeof obj != "object") return obj;

            for (const oldName in obj) {
                const newName = pascalCase(oldName);

                if (newName !== oldName && Object.prototype.hasOwnProperty.call(obj, oldName)) {
                    obj[newName] = obj[oldName];
                    delete obj[oldName];
                }

                if (typeof obj[newName] == "object") {
                    obj[newName] = await dispatch("pascalCaseInCamel", obj[newName]);
                }
            }

            return obj;
        },
    },
});
