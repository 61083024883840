import i18n from "../i18n.js";

export function isLongTypes(questType) {
    const typesLonger = [6];
    return typesLonger.includes(questType);
}
export function getQuestTypes() {
    return [
        {
            value: 0,
            label: "IQPlagueSkill",
        },
        {
            value: 1,
            label: "IQHeadReward",
        },
        {
            value: 2,
            label: "IQCases",
        },
        {
            value: 3,
            label: "OreBonus",
        },
        {
            value: 4,
            label: "XDChinookEvent",
        },
        {
            value: 5,
            label: i18n.t("gather"),
        },
        {
            value: 6,
            label: i18n.t(`kill`),
        },
        {
            value: 7,
            label: i18n.t(`craft`),
        },
        {
            value: 8,
            label: i18n.t(`studi`),
        },
        {
            value: 9,
            label: i18n.t(`loot`),
        },
        {
            value: 10,
            label: i18n.t(`upgrade`),
        },
        {
            value: 11,
            label: i18n.t(`usekeycard`),
        },
        {
            value: 12,
            label: i18n.t(`install`),
        },
        {
            value: 13,
            label: i18n.t(`PurchaseFromNpc`),
        },
        {
            value: 14,
            label: i18n.t(`HackCrate`),
        },
        {
            value: 15,
            label: i18n.t(`RecycleItem`),
        },
        {
            value: 16,
            label: i18n.t(`Growseedlings`),
        },
        {
            value: 17,
            label: `Raidable Bases By Nivex`,
        },
        {
            value: 18,
            label: i18n.t(`Fishing`),
        },
        {
            value: 19,
            label: `BossMonster`,
        },
        {
            value: 20,
            label: `HarborEvent`,
        },
        {
            value: 21,
            label: `SatelliteDishEvent`,
        },
        {
            value: 22,
            label: `Sputnik`,
        },
        {
            value: 23,
            label: `AbandonedBases`,
        },
        {
            value: 24,
            label: `Delivery`,
        },
        {
            value: 25,
            label: `IQDronePatrol`,
        },
    ];
}
export function getAudioTriggerTypes() {
    return [
        {
            value: 0,
            label: i18n.t("audioTriggerTypes.greeting"),
        },
        {
            value: 1,
            label: i18n.t("audioTriggerTypes.farewell"),
        },
        {
            value: 2,
            label: i18n.t("audioTriggerTypes.taskAcceptance"),
        },
        {
            value: 3,
            label: i18n.t("audioTriggerTypes.taskCompletion"),
        },
    ];
}

export const QuestTargetTypesSwipe = [
    {
        value: "1",
        label: "Green card",
    },
    {
        value: "2",
        label: "Blue card",
    },
    {
        value: "3",
        label: "Red card",
    },
];
export const QuestTargetTypesRaidableBases = [
    {
        value: "0",
        label: "Easy",
    },
    {
        value: "1",
        label: "Medium",
    },
    {
        value: "2",
        label: "Hard",
    },
    {
        value: "3",
        label: "Expert",
    },
    {
        value: "4",
        label: "Nightmare",
    },
];

export function getQuestTargetTypesUpgradeBuilding() {
    return [
        {
            value: "1",
            label: i18n.t("UpgradeBuild.wood"),
        },
        {
            value: "2",
            label: i18n.t("UpgradeBuild.stone"),
        },
        {
            value: "3",
            label: i18n.t("UpgradeBuild.metal"),
        },
        {
            value: "4",
            label: i18n.t("UpgradeBuild.hqmetal"),
        },
    ];
}

export function getQuestPrizeTypes() {
    return [
        {
            value: 0,
            label: i18n.t(`item`),
        },
        {
            value: 1,
            label: i18n.t(`blueprint`),
        },
        {
            value: 2,
            label: i18n.t(`customitem`),
        },
        {
            value: 3,
            label: i18n.t(`commandtype`),
        },
    ];
}
export const QuestTargetTypesKill = [
    "player",
    "bear",
    "polarbear",
    "stag",
    "boar",
    "chicken",
    "wolf",
    "simpleshark",
    "testridablehorse",
    "rowboat",
    "rhib",
    "kayak",
    "submarinesolo.entity",
    "submarineduo.entity",
    "scraptransporthelicopter",
    "minicopter.entity",
    "snowmobile",
    "tomahasnowmobile",
    "ch47scientists.entity",
    "bradleyapc",
    "patrolhelicopter",
    "scientistnpc_ch47_gunner",
    "scientistnpc_patrol",
    "scientistnpc_roam",
    "scientistnpc_roamtethered",
    "scientistnpc_junkpile_pistol",
    "scientistnpc_excavator",
    "scientistnpc_oilrig",
    "scientistnpc_heavy",
    "npc_underwaterdweller",
    "npc_tunneldweller",
    "scientistnpc_cargo_turret_lr300",
    "scientistnpc_cargo_turret_any",
    "scientistnpc_cargo",
    "scientistnpc_full_lr300",
    "scientistnpc_full_shotgun",
    "scientistnpc_full_any",
    "scientistnpc_full_pistol",
    "scientistnpc_full_mp5",
];

export function transformOldQuestToNew(quest) {
    return {
        QuestID: quest.id != null ? quest.id : undefined,
        QuestDisplayName: quest.displayName != null ? quest.displayName : undefined,
        QuestDisplayNameMultiLanguage: quest.displayNameEn != null ? quest.displayNameEn : undefined,
        QuestDescription: quest.description != null ? quest.description : undefined,
        QuestDescriptionMultiLanguage: quest.descriptionEn != null ? quest.descriptionEn : undefined,
        QuestMissions: quest.missions != null ? quest.missions : undefined,
        QuestMissionsMultiLanguage: quest.missionsEn != null ? quest.missionsEn : undefined,
        QuestPermission: quest.permissions != null ? quest.permissions : undefined,
        QuestType: quest.questType != null ? quest.questType : undefined,
        Target: quest.target != null ? quest.target : undefined,
        ActionCount: quest.amount != null ? quest.amount : undefined,
        IsRepeatable: quest.useRepeat != null ? quest.useRepeat : undefined,
        IsMultiLanguage: quest.multiLanguage != null ? quest.multiLanguage : undefined,
        IsReturnItemsRequired: quest.bring != null ? quest.bring : undefined,
        Cooldown: quest.cooldown != null ? quest.cooldown : undefined,
        PrizeList:
            quest.prizeList && Array.isArray(quest.prizeList)
                ? quest.prizeList.map((prize) => ({
                      PrizeName: prize.nameprize != null ? prize.nameprize : undefined,
                      PrizeType: prize.type != null ? prize.type : undefined,
                      ItemShortName: prize.shortName != null ? prize.shortName : undefined,
                      ItemAmount: prize.amount != null ? prize.amount : undefined,
                      CustomItemName: prize.name != null ? prize.name : undefined,
                      ItemSkinID: prize.skinId != null ? prize.skinId : undefined,
                      PrizeCommand: prize.command != null ? prize.command : undefined,
                      CommandImageUrl: prize.url != null ? prize.url : undefined,
                  }))
                : [],
    };
}
