<template>
    <v-toolbar height="55" elevate-on-scroll dense>
        <v-toolbar-title class="toolbar-title">
            <v-btn elevation="0" @click="$router.push('/')" class="title-btn" text> XDQuest - DezLife 7.0.0+ </v-btn>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="language-btn mr-2" @click="SwitchLanguage('en')" elevation="3" small fab>
            <span class="flag-icon flag-icon-us"></span>
        </v-btn>
        <v-btn class="language-btn mr-2" @click="SwitchLanguage('ru')" elevation="3" small fab>
            <span class="flag-icon flag-icon-ru"></span>
        </v-btn>
        <v-btn
            v-if="IsLogin"
            href="https://discord.gg/56zt62BCQD"
            target="_blank"
            class="mr-2 discord-btn beff"
            color="#5865f2"
            elevation="3"
            small
            fab
        >
            <img width="30" src="../assets/icons8-discord-new-50.png" alt="My Discord" />
        </v-btn>
        <v-btn
            v-if="IsLogin && this.$route.name !== 'RuWiki' && this.$route.name !== 'EnWiki'"
            @click="GoToWiki"
            class="wiki-btn"
            elevation="2"
            outlined
        >
            {{ $t("btnwiki") }}
            <v-icon dark right>mdi-alert</v-icon>
        </v-btn>
        <v-btn
            v-if="this.$route.name === 'RuWiki' || this.$route.name === 'EnWiki'"
            @click="$router.push('/')"
            class="back-btn"
            elevation="2"
            outlined
        >
            {{ $t("btnback") }}
            <v-icon dark right>mdi-keyboard-return</v-icon>
        </v-btn>
        <v-btn v-if="IsLogin" class="ml-3 logout-btn" @click="Logout" elevation="2" outlined>
            Выход
            <v-icon dark right>mdi-keyboard-return</v-icon>
        </v-btn>
    </v-toolbar>
</template>

<script>
import "../assets/style/icon.css";

export default {
    components: {},
    data() {
        return {};
    },
    methods: {
        GoToWiki() {
            if (this.$i18n.locale === "ru") {
                this.$router.push("/ru/wiki");
            } else {
                this.$router.push("/en/wiki");
            }
        },
        Logout() {
            this.$store.dispatch("logout");
            this.$router.push("/sign-in");
        },
        SwitchLanguage(langs) {
            this.$i18n.locale = langs;
            this.$store.commit("UPDATE_LANG", langs);
            if (this.$route.name === "RuWiki" && langs === "en") {
                this.$router.push("/en/wiki");
            } else if (this.$route.name === "EnWiki" && langs === "ru") {
                this.$router.push("/ru/wiki");
            }
        },
    },
    computed: {
        IsLogin() {
            return this.$store.getters.isLoggedIn;
        },
    },
    mounted() {
        const lang = localStorage.getItem("user_lang");
        if (lang) {
            this.$i18n.locale = lang;
        } else {
            const navigatorLanguage = navigator.language.split("-")[0];
            if (navigatorLanguage !== "ru") {
                this.$i18n.locale = "en";
            } else {
                this.$i18n.locale = "ru";
            }
            localStorage.setItem("user_lang", this.$i18n.locale.toString());
        }
    },
};
</script>

<style lang="scss" scoped>
.beff {
    border: 1px transparent;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    -webkit-animation: glowing 1300ms infinite;
    -moz-animation: glowing 1300ms infinite;
    -o-animation: glowing 1300ms infinite;
    animation: glowing 1300ms infinite;
}
@-webkit-keyframes glowing {
    0% {
        background-color: #5865f2;
    }
    50% {
        background-color: #404cdbed;
    }
    100% {
        background-color: #5865f2;
    }
}
@keyframes glowing {
    0% {
        background-color: #5865f2;
    }
    50% {
        background-color: #404cdbed;
    }
    100% {
        background-color: #5865f2;
    }
}
.toolbar-title {
    font-size: 20px;
}

.language-btn {
    font-size: 18px;
}

.discord-btn img {
    width: 30px;
}

.wiki-btn,
.back-btn,
.logout-btn {
    display: flex;
    align-items: center;
}
</style>
